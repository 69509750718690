import './App.css';
import React, { useState, useEffect } from 'react';
import buck1 from './pics/buck-1.jpeg';
import buck2 from './pics/buck-2.jpeg';
import buck3 from './pics/buck-3.jpeg';
import buck4 from './pics/buck-4.jpeg';
import buck5 from './pics/buck-5.jpeg';
import buck6 from './pics/buck-6.jpeg';
import buck7 from './pics/buck-7.jpeg';
import buck8 from './pics/buck-8.jpeg';
import buck9 from './pics/buck-9.jpeg';
import buck10 from './pics/buck-10.jpeg';
import buck11 from './pics/buck-11.jpeg';
import buck12 from './pics/buck-12.jpeg';
import buck13 from './pics/buck-13.jpeg';
import buck14 from './pics/buck-14.jpeg';
import buck15 from './pics/buck-15.jpeg';
import buck16 from './pics/buck-16.jpeg';

function App() {
  
  const [buckImage, setBuckImage] = useState('')

  const randomBuckImg = () => {
    const buckArray = [buck1, buck2, buck3, buck4, buck5, buck6, buck7, buck8, buck9, buck10, buck11, buck12, buck13, buck14, buck15, buck16]
    const buckImg = buckArray[Math.floor(Math.random() * (15 - 0 + 1) + 0)]
    console.log(buckImg)
    setBuckImage(buckImg)
    return
  }

  useEffect(() => {
    randomBuckImg()
  }, [])

  return (
    <div className= 'App'> 
      <div className= 'main-buck-container'>
        <h1>Suki Bink!!!</h1>
        <img src={buckImage} alt='buckmunster'></img>
      </div>
    </div>
  );
}

export default App;
